import React from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import InfoCards from '../InfoCards'; // Asegúrate de que esta ruta sea correcta
import sangre from '../../imagenes/sangre.jpg';
import psicologia from '../../imagenes/psicologia.jpg';
import medicina from '../../imagenes/medicina.jpg';
import oftalmologia from '../../imagenes/oftalmologia.jpg';
import medicos from '../../imagenes/medicos.jpg';
import titleimage from '../../imagenes/serviciosTitle.jpg';

// Estilos para los componentes
const ImageContainer = styled(Box)({
  width: 'auto', // Ocupa todo el ancho
  height: 'auto', // Ajusta la altura automáticamente
  padding: '0',
  position: 'relative',
  margin:'0',
});

const Image = styled('img')({
  width: '100%', // Ocupa el 100% del ancho del contenedor
  height: 'auto', // Ajusta la altura para mantener la proporción
  objectFit: 'cover', // Ajusta la imagen para que se muestre completa sin recortes
});

const CardsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap', // Permite que las tarjetas se envuelvan
  justifyContent: 'center',
  gap: '20px',
  marginTop: '20px',
});

const RequirementsContainer = styled(Box)({
  fontFamily: 'sans-serif',
  color: '#2E4E6C',
  fontSize: '18px',
  margin: '20px',
  textAlign: 'center',
});

const CardText = styled(Typography)({
  color: '#2E4E6C', // Color del texto
  textAlign: 'center',
  fontFamily: 'PT Sans, sans-serif',
});

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
  minHeight: 'calc(100vh - 64px)',
  boxSizing: 'border-box',
  padding: '0 20px', // Añadido padding para evitar que el contenido toque los bordes
});

const TitleContainer = styled(Box)({
  padding: '20px 30px',
  margin: '5px',
  textAlign: 'center',
  width: '45%',
});

const StyledInfoCard = styled(InfoCards)({
  width: '200px', // Ajusta el ancho según sea necesario
  height: '300px', // Ajusta la altura según sea necesario
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const BlueBackgroundContainer = styled(Grid)({
  backgroundColor: 'rgb(0, 0, 128)', // Azul en formato RGB
  padding: '5px 20px', // Ajusta el padding según tus necesidades
  width:'100%',
});

const Evaluaciones = () => {
  return (
    <MainContainer>
      <TitleContainer>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <Image src={titleimage} alt="ImgTitle" />
          </Grid>
        </Grid>
        </TitleContainer>  
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Typography
              sx={{
                fontFamily: 'PT Sans, sans-serif',
                margin: '0',
                color: '#2E4E6C',
                fontSize: '20px',
                textAlign: 'center',
              }}
            >
              A continuación te presentamos nuestros diferentes tipos de examen médico para que obtengas o revalides tu brevete rápido, legal y seguro.
            </Typography>
          </Grid>
        </Grid>
      

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <ImageContainer>
            <Image src={medicos} alt="medicos" />
          </ImageContainer>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={8}>
        <BlueBackgroundContainer container justifyContent="center">
          <Typography
            sx={{
              fontFamily: 'PT Sans, sans-serif',
              fontSize: '45px',
              textAlign: 'center',
              color: 'white',
              fontWeight: '700', // Hacer la fuente más gruesa
              fontStyle: 'italic', // Hacer la fuente cursiva
              letterSpacing: '1px',
            }}
          >
            Tipos de Exámenes
          </Typography>
        </BlueBackgroundContainer>
      </Grid>
    </Grid>
        

      <CardsContainer>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledInfoCard
            title={<RequirementsContainer>GRUPO SANGUÍNEO</RequirementsContainer>}
            text={<CardText>Consiste en tomar una muestra de sangre para identificar grupo y factor sanguíneo. No temas, la muestra se obtiene del dedo por punción capilar y sólo son necesarias tres gotas de sangre.</CardText>}
            image={sangre}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledInfoCard
            title={<RequirementsContainer>PSICOLOGÍA</RequirementsContainer>}
            text={<CardText>La evaluación psicológica es la más extensa del examen médico. Consta de los siguientes test: Test de Retención Visual de Benton, Test De Dominós (prueba de inteligencia), Inventario de Personalidad de Eysenck (B) (prueba de personalidad), Test De Machover (consiste en dibujar una figura humana), Test De Audit (test que permite identificar los trastornos por consumo de alcohol)</CardText>}
            image={psicologia}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledInfoCard
            title={<RequirementsContainer>MEDICINA GENERAL</RequirementsContainer>}
            text={<CardText>Te tomarán la presión, pasarás una prueba de equilibrio, realizarás una prueba de fuerza muscular donde te pedirán cargar unas pesas de 5 kg sobre la altura de tu cabeza y el médico te realizará una breve entrevista.</CardText>}
            image={medicina}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledInfoCard
            title={<RequirementsContainer>OFTALMOLOGIA / OTORRINO</RequirementsContainer>}
            text={<CardText>Consiste en tomar una muestra de sangre para identificar grupo y factor sanguíneo. No temas, la muestra se obtiene del dedo por punción capilar y sólo son necesarias tres gotas de sangre.</CardText>}
            image={oftalmologia}
          />
        </Grid>
      </CardsContainer>
    </MainContainer>
  );
};

export default Evaluaciones;
