import React, { useState } from 'react';
import '../estilos/Reservar.css';

const Reservar = () => {
    const [formData, setFormData] = useState({
        nombres: '',
        apellidos: '',
        examen: '',
        documento: '',
        numeroDocumento: '',
        correo: '',
        telefono: '',
        fecha: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Datos del formulario:', formData);
        // Aquí puedes manejar el envío de los datos
    };

    return (
        <div className="reservar-page">
        <div className="form-container">
            <div className="title-container">
            <h1>RESERVA AQUÍ</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    id="nombres" 
                    name="nombres" 
                    placeholder="Nombres" 
                    value={formData.nombres} 
                    onChange={handleChange} 
                    required 
                />
                
                <input 
                    type="text" 
                    id="apellidos" 
                    name="apellidos" 
                    placeholder="Apellidos" 
                    value={formData.apellidos} 
                    onChange={handleChange} 
                    required 
                />
                
                <select 
                    id="examen" 
                    name="examen" 
                    value={formData.examen} 
                    onChange={handleChange} 
                    required
                >
                    <option value="" disabled>--Elige Tu Examen Médico</option>
                    <option value="examen_general">Examen General</option>
                    <option value="examen_vista">Examen de Vista</option>
                    <option value="examen_audicion">Examen de Audición</option>
                </select>
                
                <select 
                    id="documento" 
                    name="documento" 
                    value={formData.documento} 
                    onChange={handleChange} 
                    required
                >
                    <option value="" disabled>--Tipo de Documento</option>
                    <option value="dni">DNI</option>
                    <option value="pasaporte">Pasaporte</option>
                    <option value="carnet_extranjeria">Carnet de Extranjería</option>
                </select>
                
                {/* Condicional para mostrar el número de documento si selecciona algún tipo de documento */}
                {formData.documento && (
                    <input
                        type="text"
                        id="numeroDocumento"
                        name="numeroDocumento"
                        placeholder="Número de Documento"
                        value={formData.numeroDocumento}
                        onChange={handleChange}
                        required
                    />
                )}
                
                <input 
                    type="email" 
                    id="correo" 
                    name="correo" 
                    placeholder="Correo" 
                    value={formData.correo} 
                    onChange={handleChange} 
                    required 
                />
                
                <input 
                    type="tel" 
                    id="telefono" 
                    name="telefono" 
                    placeholder="Teléfono" 
                    value={formData.telefono} 
                    onChange={handleChange} 
                    required 
                />
                
                <input 
                    type="date" 
                    id="fecha" 
                    name="fecha" 
                    value={formData.fecha} 
                    onChange={handleChange} 
                    required 
                />
                
                <button className="button-Reservar" type="submit">Reservar</button>
            </form>
        </div>
    </div>
    );
};

export default Reservar;
