import React from 'react';
import MenuAppBar from './MenuAppBar';

const Header = ({ onLogoClick }) => (
  <header>
    <MenuAppBar onLogoClick={onLogoClick} />
  </header>
);

export default Header;
