import React from 'react';
import { Box, Container, Typography, Link, Grid, IconButton, Divider } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Footer = () => {
  return (
    <Box 
      component="footer" 
      py={4} 
      sx={{ 
        background: 'linear-gradient(to bottom, #004d99, #007acc)', 
        color: 'white',
        mt: 5 
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          {/* Enlaces relacionados */}
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                Enlaces Relacionados
              </Typography>
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {[
                  { text: 'Simulacro Examen Reglas', href: '#' },
                  { text: 'Reserva de citas', href: 'https://ceval.kalelsac.com/citas' },
                  { text: 'Aula virtual', href: 'https://aulavirtual.kalelsac.com/' },
                  { text: 'Tramites de Licencia', href: 'https://kalelsac.com/' },
                ].map((link, idx) => (
                  <li key={idx} style={{ marginBottom: '0.5rem' }}>
                    <Link 
                      href={link.href} 
                      underline="none" 
                      sx={{ color: 'white', transition: 'color 0.3s', '&:hover': { color: '#00e6e6' } }}
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
          </Grid>

          {/* Contacto */}
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                Contacto
              </Typography>
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {[
                  { text: '925984699', href: 'https://wa.me/1234567890' },
                  { text: '925984699', href: 'https://wa.me/0987654321' },
                ].map((contact, idx) => (
                  <li key={idx} style={{ marginBottom: '0.5rem' }}>
                    <Link 
                      href={contact.href} 
                      underline="none" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      sx={{ color: 'white', display: 'flex', alignItems: 'center', gap: '0.5rem', transition: 'color 0.3s', '&:hover': { color: '#00e6e6' } }}
                    >
                      <WhatsAppIcon /> {contact.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
          </Grid>

          {/* Síguenos */}
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                Síguenos
              </Typography>
              <Box>
                {[
                  { icon: <FacebookIcon />, href: 'https://facebook.com' },
                  { icon: <TwitterIcon />, href: 'https://twitter.com' },
                  { icon: <InstagramIcon />, href: 'https://instagram.com' },
                ].map((social, idx) => (
                  <IconButton 
                    key={idx} 
                    href={social.href} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    sx={{ color: 'white', mx: 1, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.2)' } }}
                  >
                    {social.icon}
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3, borderColor: 'rgba(255, 255, 255, 0.5)' }} />

        <Typography variant="body2" align="center" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          &copy; 2024 rutasysalud. Todos los derechos reservados.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
