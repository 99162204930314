import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box
} from '@mui/material';
import { FaWaze } from 'react-icons/fa';
import GoogleIcon from '@mui/icons-material/Google';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/material/styles';

// Estilos personalizados para la tarjeta
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  margin: theme.spacing(2),
  transition: 'transform 0.3s, box-shadow 0.3s',
  boxShadow: theme.shadows[4], // Añade sombra por defecto
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6], // Sombra al pasar el mouse
  },
}));

// Estilos para la imagen
const StyledCardMedia = styled(CardMedia)({
  height: 250,
  objectFit: 'cover',
});

// Componente LocationCard que recibe props para ser reutilizable
const LocationCard = ({ image, title, description, hours, phone, whatsapp, locationUrl, wazeUrl }) => {
  return (
    <StyledCard>
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          height: 250,
          objectFit: 'cover',
        }}
      />
      <CardContent>
        <Typography 
          variant="h5" 
          component="div" 
          align="center" 
          gutterBottom
          sx={{ fontFamily: 'Asap Condensed, sans-serif' }}
        >
          {title}
        </Typography>

        {/* Contenedor principal centrado */}
        <Box mt={2} mb={2} display="flex" flexDirection="column" alignItems="center">
          <Typography 
            variant="body2" 
            color="text.secondary" 
            align="left" 
            paragraph
            sx={{ fontFamily: 'Asap Condensed, sans-serif' }}
          >
            {description}
          </Typography>

          {/* Sección de horarios con icono de reloj */}
          {hours && (
            <Box display="flex" alignItems="center" mb={1}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ fontFamily: 'Asap Condensed, sans-serif' }}
              >
                {hours}
              </Typography>
            </Box>
          )}

          {/* Sección de números de teléfono con iconos */}
          {phone && (
            <Box display="flex" alignItems="center" mb={1}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ fontFamily: 'Asap Condensed, sans-serif' }}
              >
                {phone}
              </Typography>
            </Box>
          )}
          {whatsapp && (
            <Box display="flex" alignItems="center" mb={1}>
              <WhatsAppIcon sx={{ mr: 1 }} />
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ fontFamily: 'Asap Condensed, sans-serif' }}
              >
                {whatsapp}
              </Typography>
            </Box>
          )}
        </Box>

        {/* Botones para abrir Google Maps y Waze */}
        <Box display="flex" justifyContent="center" gap={2} mt={2}>
          <Button
            variant="contained"
            color="primary"
            href={locationUrl}
            target="_blank"
            startIcon={<GoogleIcon />}
            sx={{ fontFamily: 'Asap Condensed, sans-serif' }}
          >
            Maps
          </Button>
          <Button
            variant="contained"
            color="secondary"
            href={wazeUrl}
            target="_blank"
            startIcon={<FaWaze size={24} />}
            sx={{ fontFamily: 'Asap Condensed, sans-serif' }}
          >
            Waze
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default LocationCard;
