import React from 'react';
import { Box, Button, Menu, MenuItem, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const pages = [
  { name: 'Inicio', path: '/' },
  { name: 'Nosotros', path: '/nosotros' },
  { name: 'Servicios', path: '/servicios' },
  { name: 'Preguntas', path: '/preguntas' },
  { name: 'Reserva tu cita', path: '/reservar' }
];

const DesktopMenu = () => {
  const [anchorElServices, setAnchorElServices] = React.useState(null);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleOpenServicesMenu = (event) => {
    setAnchorElServices(event.currentTarget);
  };

  const handleCloseServicesMenu = () => {
    setAnchorElServices(null);
  };

  const handleOpenMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleSelectService = (servicePath) => {
    handleCloseServicesMenu();
    handleCloseMenu();
    navigate(`/${servicePath}`);
  };

  const isActive = (pagePath) => location.pathname === pagePath;

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {/* Menú para pantallas pequeñas */}
      <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
        <IconButton onClick={handleOpenMenu} >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
          PaperProps={{
            sx: {
              width: '100%',
              maxWidth: 'none',
            },
          }}
        >
          {pages.map((page) => (
            page.name === 'Servicios' ? (
              <MenuItem key={page.name} onClick={handleOpenServicesMenu}>
                <span style={{
                  textDecoration: 'none',
                  color: 'rgb(10, 51, 128)',
                  fontWeight: isActive(page.path) ? 'bold' : 'normal',
                  padding: '6px 12px',
                  width: '100%',
                  display: 'block',
                }}>
                  {page.name}
                </span>
              </MenuItem>
            ) : (
              <MenuItem key={page.name} onClick={handleCloseMenu}>
                <Link
                  to={page.path}
                  style={{
                    textDecoration: 'none',
                    color: 'rgb(10, 51, 128)',
                    fontWeight: isActive(page.path) ? 'bold' : 'normal',
                    padding: '6px 12px',
                    width: '100%',
                    display: 'block',
                    backgroundColor: isActive(page.path) ? 'lightgray' : 'transparent',
                  }}
                >
                  {page.name}
                </Link>
              </MenuItem>
            )
          ))}
          <Menu
            anchorEl={anchorElServices}
            open={Boolean(anchorElServices)}
            onClose={handleCloseServicesMenu}
            PaperProps={{
              sx: {
                width: '200px',
                maxWidth: 'none',
              },
            }}
          >
            {['evaluaciones', 'revalidacion'].map((service) => (
              <MenuItem key={service} onClick={() => handleSelectService(service)}>
                <span
                  style={{
                    textDecoration: 'none',
                    color: 'rgb(10, 51, 128)',
                    fontWeight: location.pathname.includes(service) ? 'bold' : 'normal',
                    padding: '6px 12px',
                    width: '100%',
                    display: 'block',
                    backgroundColor: location.pathname.includes(service) ? 'lightgray' : 'transparent',
                  }}
                >
                  {service.toUpperCase()}
                </span>
              </MenuItem>
            ))}
          </Menu>
        </Menu>
      </Box>

      {/* Menú para pantallas grandes */}
      <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 6 }}>
        {pages.map((page) => (
          page.name === 'Servicios' ? (
            <React.Fragment key={page.name}>
              <Button
                sx={{
                  my: 2,
                  color: 'white',
                  fontWeight: isActive(page.path) ? 'bold' : 'bold',
                  backgroundColor: isActive(page.path) ? 'rgba(100, 149, 237, 0.3)' : 'transparent',
                  borderRadius: '4px',
                  padding: '6px 12px',
                }}
                onClick={handleOpenServicesMenu}
              >
                {page.name}
              </Button>
              <Menu
                anchorEl={anchorElServices}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElServices)}
                onClose={handleCloseServicesMenu}
                PaperProps={{
                  sx: {
                    width: '200px',
                    maxWidth: 'none',
                  },
                }}
              >
                {['evaluaciones', 'revalidacion'].map((service) => (
                  <MenuItem key={service} onClick={() => handleSelectService(service)}>
                    <span
                      style={{
                        textDecoration: 'none',
                        color: 'rgb(10, 51, 128)',
                        fontWeight: location.pathname.includes(service) ? 'bold' : 'normal',
                        padding: '6px 12px',
                        width: '100%',
                        display: 'block',
                        backgroundColor: location.pathname.includes(service) ? 'lightgray' : 'transparent',
                      }}
                    >
                      {service.toUpperCase()}
                    </span>
                  </MenuItem>
                ))}
              </Menu>
            </React.Fragment>
          ) : (
            <Button
              key={page.name}
              component={Link}
              to={page.path}
              sx={{
                my: 2,
                color: 'white',
                fontWeight: isActive(page.path) ? 'bold' : 'normal',
                backgroundColor: isActive(page.path) ? 'rgba(100, 149, 237, 0.3)' : 'transparent',
                borderRadius: '4px',
                padding: '6px 12px',
              }}
            >
              {page.name}
            </Button>
          )
        ))}
      </Box>
    </Box>
  );
};

export default DesktopMenu;
