// src/components/Slider.js
import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import img1 from '../imagenes/slider02.jpg';  
import img2 from '../imagenes/slider01.jpg'; 
import '../estilos/Slider.css'; 

const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
];

const Slider = () => {
  return (
    <div className='slider-container'>
      <ImageGallery 
        items={images}
        autoPlay={true}
        showThumbnails={false}
        infinite={true}
        slideInterval={6000}
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
      />
    </div>
  );
};

export default Slider;
