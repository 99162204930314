// src/componentes/PhoneButton.js
import React from 'react';
import { Button, Grid } from '@mui/material';
import WhatsAppImage from '../../imagenes/icons-whatsApp.jpg'; // Reemplaza con la ruta de tu imagen

const PhoneButton = ({ number }) => (
  <Grid container justifyContent="center" alignItems="center">
    <Grid item xs={12} sm="auto">
      <Button
        sx={{
          color: 'rgb(0, 0, 0)', // Color del texto
          cursor: 'pointer', // Cursor pointer
          display: 'block', // Display block
          fontFamily: 'Encode Sans, San Serif', // Fuente personalizada
          fontSize: '28px', // Tamaño de fuente
          fontWeight: 500, // Peso de fuente
          lineHeight: '40px', // Altura de línea
          padding: '5px', // Padding interno para ajustar el tamaño
          textAlign: 'center', // Alineación del texto
          '&:hover': {
          },
        }}
        href={`https://wa.me/${number}`} // Redirigir a WhatsApp
        target="_blank" // Abrir en una nueva pestaña
        rel="noopener noreferrer" // Mejora la seguridad
      >
        <img
          src={WhatsAppImage}
          alt="WhatsApp"
          style={{
            width: '35px', // Tamaño de la imagen
            height: '35px', // Mantener proporciones de la imagen
            verticalAlign: 'middle', // Alineación vertical
            marginRight: '8px', // Espacio entre la imagen y el número
          }}
        />
        {number}
      </Button>
    </Grid>
  </Grid>
);

export default PhoneButton;
