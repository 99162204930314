// src/componentes/WhatsAppFloat.js
import React from 'react';
import '../estilos/WhatsAppFloat.css'; // Asegúrate de que esta ruta sea correcta
import whatsappImage from '../imagenes/icons-whatsApp.jpg';  // Asegúrate de que la ruta sea correcta

const WhatsAppFloat = () => {
  return (
    <div className="whatsapp-float-container">
      <a
        href="https://wa.me/51925984699" // Reemplaza con tu número de WhatsApp
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-float"
      >
        <img 
          src={whatsappImage} 
          alt="WhatsApp" 
          className="whatsapp-image"
        />
        <div className="info-box">¡Habla con nosotros!</div>
      </a>
    </div>
  );
};

export default WhatsAppFloat;
