import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Box, 
  Container, 
  IconButton, 
  Menu, 
  MenuItem, 
  Grid, 
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import Logo from './Logo';
import DesktopMenu from './DesktopMenu';
import PhoneButton from '../header/PhoneButton';
import Nosotros from '../../paginas/Nosotros';
import Reservar from '../../paginas/Reservar';
import Evaluaciones from '../../paginas/Servicios';

const MenuAppBar = ({ onLogoClick }) => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (component) => {
    setSelectedComponent(component);
    handleMenuClose();
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 900) {
        handleMenuClose();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* Header with Logo and Contact Buttons */}
      <Box
        sx={{
          background: 'linear-gradient(to right, #f0f0f0, #ffffff)',
          py: { xs: 2, md: 3 },
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2} alignItems="center">
            {/* Logo Column */}
            <Grid item xs={12} md={6} display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }}>
              <Logo
                sx={{
                  width: { xs: '120px', md: '180px' },
                  height: 'auto',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  }
                }}
                onClick={onLogoClick}
              />
            </Grid>

            {/* Contact Buttons Column */}
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <Box 
                sx={{ 
                  display: 'flex', 
                  gap: 2,
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
              >
                <PhoneButton 
                  number="925984699" 
                  variant="contained" 
                  color="primary"
                  sx={{ 
                    borderRadius: '25px',
                    textTransform: 'none',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    '&:hover': {
                      boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                    }
                  }}
                />
                <PhoneButton 
                  number="925984699" 
                  variant="outlined" 
                  color="primary"
                  sx={{ 
                    borderRadius: '25px',
                    textTransform: 'none',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Desktop Navigation */}
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Container maxWidth="lg">
          <Toolbar 
            sx={{ 
              minHeight: '70px', 
              justifyContent: 'center' 
            }}
          >
            <DesktopMenu 
              onMenuClick={handleMenuItemClick} 
              sx={{
                '& .MuiButton-root': {
                  color: 'white',
                  mx: 1,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    transform: 'scale(1.05)',
                  }
                }
              }}
            />
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Navigation */}
      {isMobile && (
        <Box 
          sx={{
            position: 'fixed',
            top: 10,
            right: 10,
            zIndex: 1200,
          }}
        >
          <IconButton
            onClick={handleMenuOpen}
            sx={{ 
              color: theme.palette.primary.main, 
              backgroundColor: 'white',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.9)',
              }
            }}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{
              '& .MuiPaper-root': {
                width: '100vw',
                height: '100vh',
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }
            }}
          >
            <Box 
              sx={{ 
                position: 'absolute', 
                top: 10, 
                right: 10 
              }}
            >
              <IconButton onClick={handleMenuClose} sx={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            </Box>

            {['Inicio', 'Nosotros', 'Servicios', 'Reserva tu Cita'].map((text, index) => (
              <MenuItem 
                key={text}
                onClick={() => handleMenuItemClick(text.toLowerCase())}
                sx={{
                  width: '80%',
                  textAlign: 'center',
                  my: 1,
                  borderRadius: '25px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    transform: 'scale(1.05)',
                  }
                }}
              >
                <Typography variant="h6" sx={{ color: 'white' }}>
                  {text}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}

      {/* Render selected component */}
      {selectedComponent === 'nosotros' && <Nosotros />}
      {selectedComponent === 'reservar' && <Reservar />}
      {selectedComponent === 'evaluaciones' && <Evaluaciones />}
    </>
  );
};

export default MenuAppBar;